* {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Nunito Sans', sans-serif;
}

#root {
  width: 100%;
  height: 100%;
}

.demo {
  display: flex;
  width: 100%;
  overflow: hidden;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.demo ::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.demo ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

.demo ::-webkit-scrollbar-thumb {
  background-color: #dddd;
  border: 5px solid #d8d8d8;
}

.tableWrapper,
.pageWrapper {
  height: 100%;
  /* padding: 20px; */
  overflow: auto;
  flex: 1;
  margin: 0;
}

.settingsDrawer input[type="checkbox"] {
  cursor: pointer;
}

.settingsDrawer input[type="text"],
.settingsDrawer input[type="number"],
.settingsDrawer select {
  background: #eef2f5;
  outline: none;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  width: 120px;
}

.pageWrapper {
  padding: 32px;
  padding-top: 16px;
}
.button-section {
  display: flex;
  align-items: center;
  padding: 16px 0 64px 0;
  width: 100%;
  justify-content: space-between;
}
.source-provider {
  width: fit-content;
}
.source-provider,
.autocompelete-section,
.button-array,
.upload-section {
  display: inline-flex;
  align-items: center;
}
.autocompelete-section {
  justify-content: left;
}
.button-array {
  justify-content: center;
  width: max-content;
}
.button-section button {
  margin: 0 1em 0 1em;
  margin-right: 0;
  /* min-width: 120px; */
}
.button-array {
  margin: -1em;
}
.upload-section {
  width: fit-content;
}
.autocompelete-section > span {
  padding-right: 20px;
}
.upload-section button {
  min-width: 120px;
}

#red_color {
  color: red;
}

/* Rectangle 353 */
.file {
  margin-top: 40px;
}

.acc_con {
  width: 500px;
  margin-bottom: 8px !important;

  /* Heavy/Subtitle */

  /* identical to box height, or 117% */
}

.MuiOutlinedInput-inputMarginDense{
  /* padding-bottom: 8px !important; */
  /* padding-bottom: 6px; */
}

.rgt-cell-header-undefined{
  width: 0px!important;
}

.Toastify__toast-container {
  
  min-width: 320px;

}

.Toastify__toast--success {
  background: #edf7ed !important;
  color: #4CAF50;

}

.Toastify__close-button--success {
  color: #4CAF50 !important;
  opacity: 1;
}

.Toastify__toast--warning {
  background: #fff6d1 !important;
  color: #f5c60a;

}

.Toastify__close-button--warning {
  color: #f5c60a !important;
  opacity: 1;
}


.Toastify__toast--error {
  background: #fceceb !important;
  color: #F44336;

}

.Toastify__close-button--error {
  color: #F44336 !important;
  opacity: 1;
}
